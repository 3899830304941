import { Injectable } from '@angular/core';
import { ReportsService } from '@backoffice/ng-backoffice-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { select, Store } from '@ngrx/store';

import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';

import { calculateDate } from '../../../../utils/time.util';
import { UNIDENTIFIED_DRIVER_RESOURCE_ID } from '../../constants/report-search-bar';
import { ResourcesType } from '../../constants/resource';

import { reportsEldsAPIActions } from './reports-elds-actions';

import { selectStoreEldReports } from './reports-elds.selectors';

@Injectable()
export class ReportsEldsEffects {
  constructor(
    private actions$: Actions,
    private readonly reportsService: ReportsService,
    private readonly store: Store
  ) {}

  readonly getEldsReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportsEldsAPIActions.eldSearchBarUpdate),
      withLatestFrom(this.store.select(selectStoreEldReports)),
      exhaustMap(([, eldReports]) => {
        const { searchBar, pagination } = eldReports;
        const currentDate = new Date();
        // If the resource type selected by user is driver
        // we need to call the eld driver route
        if (
          searchBar.resourcesType === ResourcesType.DRIVER &&
          searchBar.resourceIds &&
          searchBar.resourceIds?.length > 0 &&
          searchBar.resourceIds?.some(
            (id) => id !== UNIDENTIFIED_DRIVER_RESOURCE_ID
          )
        ) {
          return this.reportsService
            .reportsControllerGetEldReportsByDrivers(
              calculateDate(searchBar.date)?.toISOString() ||
                currentDate.toISOString(),
              currentDate.toISOString(),
              pagination.currentPage,
              searchBar.resourceIds
            )
            .pipe(
              map((eldsReports) => ({
                type: reportsEldsAPIActions.eldReportsLoaded.type,
                eld: {
                  loaded: true,
                  elds: eldsReports.reports,
                  error: null,
                  searchBar,
                  pagination: {
                    currentPage: eldsReports.currentPage,
                    totalPage: eldsReports.totalPage,
                  },
                },
              })),
              catchError((error: unknown) =>
                of({
                  type: reportsEldsAPIActions.eldReportsError.type,
                  error: {
                    message: 'Error loading elds',
                    error: JSON.stringify(error),
                  },
                })
              )
            );
        }

        if (
          searchBar.resourcesType === ResourcesType.DRIVER &&
          searchBar.resourceIds &&
          searchBar.resourceIds?.length > 0 &&
          searchBar.resourceIds?.some(
            (id) => id === UNIDENTIFIED_DRIVER_RESOURCE_ID
          )
        ) {
          return this.reportsService
            .reportsControllerGetUnidentifiedEldReports(
              calculateDate(searchBar.date)?.toISOString() ||
                currentDate.toISOString(),
              currentDate.toISOString(),
              pagination.currentPage
            )
            .pipe(
              map((eldsReports) => ({
                type: reportsEldsAPIActions.eldReportsLoaded.type,
                eld: {
                  loaded: true,
                  elds: eldsReports.reports,
                  error: null,
                  searchBar,
                  pagination: {
                    currentPage: eldsReports.currentPage,
                    totalPage: eldsReports.totalPage,
                  },
                },
              })),
              catchError((error: unknown) =>
                of({
                  type: reportsEldsAPIActions.eldReportsError.type,
                  error: {
                    message: 'Error loading elds',
                    error: JSON.stringify(error),
                  },
                })
              )
            );
        }

        if (
          searchBar.resourcesType === ResourcesType.VEHICLE &&
          searchBar.resourceIds &&
          searchBar.resourceIds?.length > 0
        ) {
          // If the resource type selected by user is vehicle
          return this.reportsService
            .reportsControllerGetEldReportsByVehicle(
              calculateDate(searchBar.date)?.toISOString() ||
                currentDate.toISOString(),
              currentDate.toISOString(),
              pagination.currentPage,
              searchBar.resourceIds
            )
            .pipe(
              map((eldsReports) => ({
                type: reportsEldsAPIActions.eldReportsLoaded.type,
                eld: {
                  loaded: true,
                  elds: eldsReports.reports,
                  error: null,
                  searchBar,
                  pagination: {
                    currentPage: eldsReports.currentPage,
                    totalPage: eldsReports.totalPage,
                  },
                },
              })),
              catchError((error: unknown) =>
                of({
                  type: reportsEldsAPIActions.eldReportsError.type,
                  error: {
                    message: 'Error loading elds',
                    error: JSON.stringify(error),
                  },
                })
              )
            );
        }

        return of({
          type: reportsEldsAPIActions.eldReportsLoaded.type,
          eld: {
            loaded: true,
            elds: [],
            error: null,
            searchBar,
            pagination,
          },
        });
      })
    )
  );

  readonly infiniteScrollEldsReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportsEldsAPIActions.eldDatatablePageChange),
      withLatestFrom(this.store.pipe(select(selectStoreEldReports))),
      exhaustMap(([, reportsElds]) => {
        const { pagination, elds, searchBar } = reportsElds;
        const newPage = pagination.currentPage + 1;
        const currentDate = new Date();

        if (
          searchBar.resourcesType === ResourcesType.DRIVER &&
          searchBar.resourceIds &&
          searchBar.resourceIds.length > 0 &&
          searchBar.resourceIds.some(
            (id) => id !== UNIDENTIFIED_DRIVER_RESOURCE_ID
          )
        ) {
          // need to handle correctly the route according the resource type
          return this.reportsService
            .reportsControllerGetEldReportsByDrivers(
              calculateDate(searchBar.date)?.toISOString() ||
                currentDate.toISOString(),
              currentDate.toISOString(),
              newPage,
              searchBar.resourceIds
            )
            .pipe(
              map((eldsReports) => ({
                type: reportsEldsAPIActions.eldReportsLoaded.type,
                eld: {
                  loaded: true,
                  elds: [...elds, ...eldsReports.reports],
                  error: null,
                  pagination: {
                    currentPage: newPage,
                    totalPage: eldsReports.totalPage,
                  },
                  searchBar,
                },
              })),
              catchError((error: unknown) =>
                of({
                  type: reportsEldsAPIActions.eldReportsError.type,
                  error: {
                    message: 'Error loading elds',
                    error: JSON.stringify(error),
                  },
                })
              )
            );
        }

        if (
          searchBar.resourcesType === ResourcesType.DRIVER &&
          searchBar.resourceIds &&
          searchBar.resourceIds.length > 0 &&
          searchBar.resourceIds.some(
            (id) => id !== UNIDENTIFIED_DRIVER_RESOURCE_ID
          )
        ) {
          return this.reportsService
            .reportsControllerGetUnidentifiedEldReports(
              calculateDate(searchBar.date)?.toISOString() ||
                currentDate.toISOString(),
              currentDate.toISOString(),
              newPage
            )
            .pipe(
              map((eldsReports) => ({
                type: reportsEldsAPIActions.eldReportsLoaded.type,
                eld: {
                  loaded: true,
                  elds: [...elds, ...eldsReports.reports],
                  error: null,
                  pagination: {
                    currentPage: newPage,
                    totalPage: eldsReports.totalPage,
                  },
                  searchBar,
                },
              })),
              catchError((error: unknown) =>
                of({
                  type: reportsEldsAPIActions.eldReportsError.type,
                  error: {
                    message: 'Error loading elds',
                    error: JSON.stringify(error),
                  },
                })
              )
            );
        }

        if (
          searchBar.resourcesType === ResourcesType.VEHICLE &&
          searchBar.resourceIds &&
          searchBar.resourceIds.length > 0
        ) {
          // need to handle correctly the route according the resource type
          return this.reportsService
            .reportsControllerGetEldReportsByVehicle(
              calculateDate(searchBar.date)?.toISOString() ||
                currentDate.toISOString(),
              currentDate.toISOString(),
              newPage,
              searchBar.resourceIds
            )
            .pipe(
              map((eldsReports) => ({
                type: reportsEldsAPIActions.eldReportsLoaded.type,
                eld: {
                  loaded: true,
                  elds: [...elds, ...eldsReports.reports],
                  error: null,
                  pagination: {
                    currentPage: newPage,
                    totalPage: eldsReports.totalPage,
                  },
                  searchBar,
                },
              })),
              catchError((error: unknown) =>
                of({
                  type: reportsEldsAPIActions.eldReportsError.type,
                  error: {
                    message: 'Error loading elds',
                    error: JSON.stringify(error),
                  },
                })
              )
            );
        }

        return of({
          type: reportsEldsAPIActions.eldReportsLoaded.type,
          eld: {
            loaded: true,
            elds,
            error: null,
            pagination,
            searchBar,
          },
        });
      })
    )
  );
}
