<div
  class="dropdown-input"
  [ngClass]="{
    'dropdown-input--has-value':
      selectedElement &&
      selectedElement.value &&
      selectedElement.value.toString().length > 0 &&
      !disabled,
    'dropdown-input--has-dropdown-open': itemsDropdownIsShown && !disabled,
    'dropdown-input--has-error': error && !disabled,
    'dropdown-input--is-disabled': disabled
  }"
>
  <!-- Input -->
  <label class="dropdown-item__container">
    <span
      *ngIf="label.length > 0"
      class="dropdown-label text-caption--semibold"
      >{{ label + (required ? ' *' : '') }}</span
    >
    <button
      class="dropdown-item__button"
      (click)="onDropdownClick()"
      type="button"
    >
      <div
        class="dropdown-item__placeholder-ctn"
        *ngIf="placeholder !== null && placeholder?.length"
      >
        <span class="text-p3 dropdown-item__placeholder">
          {{ placeholder }}
        </span>
        <span
          *ngIf="errorMessage && !selectedElement?.value"
          class="dropdown-item__error-message"
        >
          <span class="dropdown-item__error-message-inner">{{
            errorMessage
          }}</span>
        </span>
      </div>
      <div class="text-p3 dropdown-item__selected-value">
        {{ selectedElement?.label ? selectedElement?.label : '' }}
      </div>
      <span
        class="dropdown-item__icon-container"
        [ngClass]="{
          'dropdown-item__icon-container--negative-rotation':
            !itemsDropdownIsShown
        }"
      >
        <span class="dropdown-item__icon-container_content-icon">
          <ng-content select="[icon]"></ng-content>
        </span>
        <ad-icon
          glyph="caret_up"
          class="dropdown-item__icon-container_default-icon"
        ></ad-icon>
      </span>
    </button>
  </label>

  <!-- Menu -->
  <div
    class="dropdown-menu"
    [ngClass]="{
      'dropdown-menu--open': itemsDropdownIsShown,
      'dropdown-menu--close': !itemsDropdownIsShown
    }"
  >
    <div class="dropdown-menu__inner">
      <div class="dropdown-menu__content">
        <ng-content></ng-content>
        <div class="dropdown-list">
          <div class="dropdown-list__content">
            <button
              *ngIf="allowEmptyOption"
              class="dropdown-list__item text-p3"
              (click)="onMenuEmptyItemClick()"
              type="button"
            >
              <span class="clamp">&nbsp;</span>
            </button>

              <div
                style="width: 100%;"
                   *ngFor="let item of items"
              >
                <button
                  *ngIf="!checkBox"
                class="dropdown-list__item text-p3"
                [ngClass]="{
                'item-selected':
                  !item.disabled && item.value === selectedElement?.value,
                'item-disabled': item.disabled
              }"
                (click)="onMenuItemClick(item)"
                type="button"
              >
                <span  class="clamp">{{ item.label }} </span>
                </button>
                <ad-checkbox
                  *ngIf="checkBox"
                  [text]="item.label"
                  [disabled]="shouldDisable(item)"
                  value="{{item.value}}"
                  (click)="onCheckBoxItemClick(item)"
                  [selected]="isItemChecked(item)"
                ></ad-checkbox>
              </div>
          </div>
          <div
            class="dropdown-list__content dropdown-list__secondary-list"
            *ngIf="itemsSecondList.length"
          >
            <button
              class="dropdown-list__item text-p3"
              [ngClass]="{
                'item-selected': item.value === selectedElement?.value
              }"
              *ngFor="let item of itemsSecondList"
              (click)="onMenuItemClick(item)"
              type="button"
            >
              <span class="clamp">{{ item.label }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
